import { Copyright, FooterContainer, Inner } from "./Footer.styled";

export const Footer = () => {
  return (
    <FooterContainer>
      <Inner>
        <Copyright>Copyright © Constacts, Inc. All Rights Reserved.</Copyright>
      </Inner>
    </FooterContainer>
  );
};
