import { AnbuContainer, DevieImage, Highlight, Paragraph } from "./Anbu.styled";

export const Anbu = () => {
  return (
    <AnbuContainer>
      <Paragraph>
        We’re inventing
        <br />
        the new communications.
        <br />
        <Highlight>Anbu</Highlight> is the easiest but <br />
        most important way <br />
        to be connected.
      </Paragraph>

      <picture>
        <source srcSet={`${process.env.REACT_APP_ASSETS_URL}/assets/img-devicemockup-pc@2x.png`} media="(min-width: 1080px)" />
        <source srcSet={`${process.env.REACT_APP_ASSETS_URL}/assets/img-devicemockup-t@2x.png`} media="(min-width: 768px)" />
        <DevieImage src={`${process.env.REACT_APP_ASSETS_URL}/assets/img-devicemockup-m@2x.png`} alt="constacts app" />
      </picture>
    </AnbuContainer>
  );
};
