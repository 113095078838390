import styled from "styled-components";
import { Container } from "../styles/common/common.styled";

export const FooterContainer = styled(Container)`
  overflow: hidden;
  background-color: #e5e8ed;
`;

export const Inner = styled.div`
  margin: 25px 0 55px;
  text-align: center;

  @media (min-width: 768px) {
    margin: 63px 0;
  }
`;

export const LogoLink = styled.a`
  display: flex;
  align-items: center;

  @media (min-width: 768px) {
    height: 25px;
  }
`;

export const Link = styled.a`
  display: inline-block;
  margin-left: 10px;
  color: #8f97a0;
  font-size: 16px;
  line-height: 22px;
  vertical-align: top;

  @media (min-width: 768px) {
    margin-left: 50px;
    font-size: 18px;
    line-height: 25px;
  }
  &:first-of-type {
    margin-left: 0px;
  }
`;

export const LinkWrap = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const RightSideLinkWrap = styled.div`
  font-size: 0;
  margin-top: 14px;
  @media (min-width: 768px) {
    margin-top: 0;
  }
`;

export const Copyright = styled.div`
  margin-top: 62px;
  color: #8f97a0;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  letter-spacing: -0.26px;

  @media (min-width: 768px) {
    margin-top: 33px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.3px;
  }
`;
