import { HeroContainer } from "./Hero.styled";
import { Header } from "./Header";
import styled from "styled-components";
import { useEffect, useState } from 'react';

export const MessageWrap = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 42px;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  object-fit: scale-down;
`;

export const Title = styled.p`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  width: 98%;
  font-family: 'Poppins', 'NanumSquareEB';

   @media (min-width: 400px) {
    font-size: 22px;
  }

  @media (min-width: 768px) {
    font-size: 36px;
  }

  @media (min-width: 1080px) {
    font-size: 52px;
  }
`;

export const SubTitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 98%;
`;

export const SubTitle = styled.p`
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #777;
  font-family: 'Poppins', 'NanumSquareEB';

  @media (min-width: 768px) {
    font-size: 20px;
  }

  @media (min-width: 1080px) {
    font-size: 24px;
  }
`;

export const ButtonWrraper = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 14px;
  justify-content: center;
`;

export const AppDownloadLink = styled.a`
  width: 140px;

  @media (min-width: 768px) {
    width: 180px;
  }

  @media (min-width: 1080px) {
    width: 180px;
  }
`

export const Hero = () => {
  const [hero, setHero] = useState({
    title: 'A Simple Hello Can Save The Day',
    subtitles: [
      'A place of support, collaboration, and encouragement', 
      'Join us to connect and share kindness'
    ]
  });

  useEffect(() => {
    const fetchHeroData = async () => {
      try {
        const response = await fetch(
          'https://firestore.googleapis.com/v1/projects/constacts/databases/(default)/documents/website/hero'
        );
        const data = await response.json();
        
        setHero({
          title: data.fields.title.stringValue,
          subtitles: data.fields.subtitles.arrayValue.values.map((subtitle: any) => subtitle.stringValue)
        });
      } catch (error) {
        console.error('Can not fetch hero data', error);
      }
    };

    fetchHeroData();
  }, []);

  return (
    <HeroContainer>
      <Header />
      <MessageWrap>
        <Title>{hero.title}</Title>
        <SubTitleWrap>
          {hero.subtitles.map((subtitle, index) => (
            <SubTitle key={index}>{subtitle}</SubTitle>
          ))}
        </SubTitleWrap>
        <div>
          <ButtonWrraper>
            <AppDownloadLink href="https://apps.apple.com/app/1641060297" target="_blank" rel="noopener noreferrer">
              <img src={`${process.env.REACT_APP_ASSETS_URL}/assets/ios.png`} width='100%' alt="Apple Download Icon" />
            </AppDownloadLink>
            <AppDownloadLink href="https://play.google.com/store/apps/details?id=com.constacts.constacts" target="_blank" rel="noopener noreferrer">
              <img src={`${process.env.REACT_APP_ASSETS_URL}/assets/aos.png`} width='100%' alt="Google Download Icon" />
            </AppDownloadLink>
          </ButtonWrraper>
        </div>
      </MessageWrap>
    </HeroContainer>
  );
};
