import { Anbu } from "./components/Anbu";
import { Footer } from "./components/Footer";
import { Hero } from "./components/Hero";
import { GlobalStyle } from "./styles/global";

function App() {
  return (
    <>
      <GlobalStyle />
      <Hero />
      <Anbu />
      <Footer />
    </>
  );
}

export default App;
