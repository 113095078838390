import styled from "styled-components";

export const Wrap = styled.div<{ isOpen: boolean }>`
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  width: 147px;
  display: ${(p) => (p.isOpen ? "block" : "none")};
  padding: 22px 18px;
  background: #222;
  text-align: right;
  @media (min-width: 767px) {
    display: none;
  }
`;

export const Dimmed = styled.div<{ isOpen: boolean }>`
  position: fixed;
  z-index: 1;
  inset: 0;
  display: ${(p) => (p.isOpen ? "block" : "none")};
  background-color: rgba(0, 0, 0, 0.3);
  @media (min-width: 767px) {
    display: none;
  }
`;

export const CloseButton = styled.button`
  margin-bottom: 30px;
`;

export const Link = styled.a`
  display: block;
  margin-top: 20px;
  color: #fff;
  font-size: 18px;
  line-height: 25px;
`;
